<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                <el-tab-pane label="诸葛埋点配置" name="1">
                    <el-form class="form-box" ref="ZhuGeForm" v-bind:rules="rules" v-bind:model="ZhuGeForm" label-width="100px">
                        <el-form-item label="appkey" prop="appkey">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="ZhuGeForm.appkey" placeholder="请输入appkey"></el-input>
                        </el-form-item>
                        <el-form-item label="appsecret" prop="appsecret">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="ZhuGeForm.appsecret" placeholder="请输入appsecret"></el-input>
                        </el-form-item>
                        <el-form-item label>
                            <el-button type="primary" @click="saveZhuGeForm">提 交</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import { request } from '@/common/request';
import { deal } from '@/common/main';
import loadEvents from '@/utils/loading'
let loads = new loadEvents();
export default {
    name: '',
    components: {
        Breadcrumb,
        Pagination
    },
    data() {
        return {
            activeName: '1',
            header: {},
            menuList: ['设置', '埋点配置'],
            ZhuGeForm: {},
            rules: {
                appkey: [
                    {
                        required: true,
                        message: 'appkey 不能为空',
                        trigger: 'blur'
                    }
                ],
                appsecret: [
                    {
                        required: true,
                        message: 'appsecret不能为空',
                        trigger: 'blur'
                    }
                ],
            },
        }
    },
    created() {
        this.header.token = localStorage.getItem('token');
        this.header.platform = 'admin';
        this.loadZhuGeForm()
    },
    methods: {
        // tab切换
        handleClick(tab) {
            switch (tab.paneName) {
                case '1':
                    this.loadZhuGeForm()
                    break
            }
        },

        // 诸葛埋点配置
        loadZhuGeForm() {
            this.loading = true;
            request.get('/system/zhuge/burying/point/list').then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.ZhuGeForm = ret.data;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },

        //诸葛埋点编辑
        saveZhuGeForm() {
            this.loading = true;
            request.post('/system/zhuge/burying/point/edit', this.ZhuGeForm).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.ZhuGeForm = ret.data;
                    this.$message.success('编辑成功');
                    this.loadZhuGeForm()
                    this.$refs.ZhuGeForm.resetFields()

                } else {
                    this.$message.error(ret.msg);
                }
            });
        },

    }
}
</script>
<style scoped>
.el-form-item--small.el-form-item {
    margin-top: 30px;
}

.el-input--small {
    margin-left: 10px;
}

.el-button {
    /* margin-left: 210px; */
    margin: 60px 0 80px 0;
}

.containet {
    background: #ffffff;
}

.form-box {
    width: 100%;
}

.form-input >>> .el-input__inner {
    width: 900px;
    border-radius: 4px;
}

.dialog_box .up {
    margin-top: 10px;
    margin-bottom: -10px;
}

/* 图片样式 */
.dialog_box .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
}

.dialog_box .avatar {
    font-size: 28px;
    width: 100px;
    height: 100px;
    line-height: 100px;
}

.dialog_box .span_gray {
    font-size: 12px;
    color: gray;
}
</style>